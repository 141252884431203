<template>
    <v-row>
        <v-col cols="7" sm="9">
            <v-row>
                {{ $t('sessions.select_class') }}:
            </v-row>
            <v-row>
                <v-select
                    item-text="name"
                    item-value="id"
                    :items="courses"
                    outlined
                    v-model="radioGClass"
                    v-show="tab===0"
                    append-icon="mdi-menu-down text-primary mdi-48px"
                />
                <v-select
                    item-text="name"
                    item-value="id"
                    :items="courses"
                    outlined
                    v-model="selectedClass"
                    v-show="tab>0"
                    append-icon="mdi-menu-down text-primary mdi-48px"
                />
            </v-row>
            <v-row v-show="tab===1">
                {{ $t('sessions.select_session') }}:
            </v-row>
            <v-row v-show="tab===1">
                <v-select
                    item-text="name"
                    item-value="id"
                    :items="sessions"
                    :loading="loadingSessions"
                    :disabled="loadingSessions"
                    outlined
                    v-model="selectedSession"
                    append-icon="mdi-menu-down text-primary mdi-48px"
                />
            </v-row>
            <v-row>
                {{ tab===0 ? $t('sessions.period') : $t('sessions.select_group_tier') }}:
            </v-row>
            <v-row>
                <v-radio-group v-model="radioGPeriod" mandatory v-show="tab===0">
                    <v-radio
                        v-for="(type, i) in periods"
                        :key="`period-${i}`"
                        :label="type.label"
                        :value="type.value"
                    />
                </v-radio-group>
                <v-select
                    item-text="label"
                    item-value="value"
                    :items="tiers"
                    :disabled="loadingSessions"
                    outlined
                    v-model="selectedTier"
                    v-show="tab>0"
                    append-icon="mdi-menu-down text-primary mdi-48px"
                />
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from 'vuex'
import WorkSessionService from '../../services/workSession'

export default {
    props: {
        tab: { type: Number, default: 0 },
    },
    data() {
        return {
            loadingSessions: false,
            radioGClass: 0,
            radioGPeriod: 90,
            selectedClass: 0,
            selectedSession: 0,
            selectedTier: 0,
            sessions: [],
        }
    },
    computed: {
        ...mapState({
            courses: (state) => state.userData.courses,
        }),
        periods() {
            return [
                { label: this.$t('sessions.periods[0]'), value: 7 },
                { label: this.$t('sessions.periods[1]'), value: 30 },
                { label: this.$t('sessions.periods[2]'), value: 90 },
            ]
        },
        tiers() {
            let result = [
                { label: this.$t('sessions.all_tiers'), value: 0 },
                { label: this.$t('sessions.tier_text[0]'), value: 1 },
                { label: this.$t('sessions.tier_text[1]'), value: 2 },
                { label: this.$t('sessions.tier_text[2]'), value: 3 },
            ]
            if (this.tab === 2) {
                result = [
                    { label: this.$t('sessions.all_tiers_global'), value: 0 },
                    { label: this.$t('sessions.tier_text[0]'), value: 1 },
                    { label: this.$t('sessions.tier_text[1]'), value: 2 },
                    { label: this.$t('sessions.tier_text[2]'), value: 3 },
                ]
            }
            return result
        },
    },
    created() {
        this.radioGClass = this.courses[0].id
        this.selectedClass = this.courses[0].id
        this.loadingSessions = true
        this.loadSessions()
    },
    methods: {
        loadSessions() {
            if (this.selectedClass === 0) {
                this.loadingSessions = false
                return
            }
            WorkSessionService.getWorkSessionStatsByCourseId(this.selectedClass, null, 'WORK_GROUP')
                .then((response) => {
                    this.loadingSessions = false
                    this.mapSessions(response.content)
                })
                .catch((error) => console.error(error))
        },
        mapSessions(items) {
            this.sessions = items.map((item) => (
                {
                    id: item.challengeAssignmentId,
                    name: this.$options.filters.date(
                        item.dateTimeExecution,
                        { date: true, location: this.$i18n.locale },
                    ),
                }
            ))
            this.selectedSession = this.sessions.length > 0 ? this.sessions[0].id : 0
        },
    },
    watch: {
        radioGClass() {
            this.$emit('newClass', this.radioGClass)
        },
        radioGPeriod() {
            this.$emit('newPeriod', this.radioGPeriod)
        },
        selectedClass() {
            this.loadingSessions = true
            this.loadSessions()
            this.$emit('newClassLive', this.selectedClass)
        },
        selectedSession() {
            this.$emit('newSession', this.selectedSession)
        },
        selectedTier() {
            this.$emit('newTier', this.selectedTier)
        },
    },
}
</script>

<style scoped></style>
