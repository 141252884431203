import Services from './base-services'
import mapParameters from '../utils/map-parameters'

const service = new Services('workSession')

interface IGroupSession {
    name: string
    description: string
    skill: string
    qtyOfTests: number
    studentIdList: number[]
}
interface ISession {
    dateTimeExecution: string
    workSessionType: string
    status: boolean
    startingTime: number
    endingTime: number
    workGroupList: IGroupSession
    duration: number
}

export default {
    getWorkSessionStatsByCourseId(courseId: number, periodDays: number, sessionType: string) {
        const params = mapParameters({
            courseId: courseId ? courseId : 0,
            periodDays: periodDays ? periodDays : null,
            sessionType: sessionType ? sessionType : ''
        })
        return service.get('getWorkSessionStatsByCourseId', params)
    },
    create(teacherId: number, courseId: number, data: ISession) {

        const params = {
            teacher: { id: teacherId },
            course: { id: courseId },
            dateTimeExecution: data.dateTimeExecution ? data.dateTimeExecution : '',
            workSessionType: data.workSessionType ? data.workSessionType : null,
            status: data.status ? data.status: false,
            startingTime: data.startingTime ? data.startingTime : '',
            endingTime: data.endingTime ? data.endingTime : '',
            workGroupList: data.workGroupList ? data.workGroupList : '',
            duration: data.duration ? data.duration : 0, //TODO
        }
        return service.post('create', params)
    },
    getStudentTierListByCourseId(courseId: number) {
        const params = mapParameters({
            courseId: courseId ? courseId : 0,
        })
        return service.get('getStudentTierListByCourseId', params)
    },
    getStudentListBySession(sessionId: number) {
        const params = mapParameters({
            sessionId: sessionId ? sessionId : 0,
        })
        return service.get('getStudentListBySession', params)
    },
    getDetailBySessionAndStudent(sessionId: number, userId: number) {
        const params = mapParameters({
            sessionId: sessionId ? sessionId : 0,
            userId: userId ? userId : 0,
        })
        return service.get('getDetailBySessionAndStudent', params)
    },
    getDetailBySession(sessionId: number) {
        const params = mapParameters({
            sessionId: sessionId ? sessionId : 0,
        })
        return service.get('getDetailBySession', params)
    },
}
