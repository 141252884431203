<template>
    <v-card elevation="12" class="py-6 px-8">
        <v-row>
            <h2 class="text-h4 font-weight-black px-2">
                {{ $t('sessions.sessions_data') }}
            </h2>
        </v-row>
        <v-row>
            <v-col cols="12" lg="3" class="mt-0 mt-lg-6 mx-0 px-0">
                <SelectFilters
                    class="mx-0 px-1"
                    :tab="2"
                    v-on:newClass="setClass"
                    v-on:newClassLive="setClassLive"
                    v-on:newPeriod="setPeriod"
                    v-on:newSession="setSession"
                    v-on:newTier="setTier"
                />
            </v-col>
            <v-col cols="12" lg="9">
                <v-row justify="end" class="mr-6">
                    <v-btn-toggle
                        v-model="rank"
                        tile
                        group
                        borderless
                        mandatory
                        dense
                    >
                        <v-btn :value="7" class="pr-6 mx-1 text-lowercase text-subtitle-1 font-weight-bold" small :disabled="loading">
                            {{ $t('sessions.periods[0]') }}
                        </v-btn>
                        <v-btn :value="30" class="pr-6 mx-1 text-lowercase text-subtitle-1 font-weight-bold" small :disabled="loading">
                            {{ $t('sessions.periods[1]') }}
                        </v-btn>
                        <v-btn :value="90" class="pr-6 mx-1 text-lowercase text-subtitle-1 font-weight-bold" small :disabled="loading">
                            {{ $t('sessions.periods[2]') }}
                        </v-btn>
                    </v-btn-toggle>
                </v-row>
                <StudentsTable
                    :period="rank"
                    :course="selectedClassLive"
                    :tier="selectedTier"
                    @loading="setLoading"
                />
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

import StudentsTable from '@/components/sessions/StudentsTable.vue'
import SelectFilters from '@/components/sessions/SelectFilters.vue'

export default {
    components: {
        StudentsTable,
        SelectFilters,
    },
    data() {
        return {
            loading: true,
            rank: 90,
            selectedClass: null,
            selectedClassLive: null,
            selectedPeriod: 90,
            selectedSession: 0,
            selectedTier: 0,
        }
    },
    methods: {
        setClass(classParam) {
            this.selectedClass = classParam
        },
        setClassLive(classParam) {
            this.selectedClassLive = classParam
        },
        setLoading(loading) {
            this.loading = loading
        },
        setPeriod(period) {
            this.selectedPeriod = period
        },
        setSession(session) {
            this.selectedSession = session
        },
        setTier(tier) {
            this.selectedTier = tier
        },
    },
}
</script>
<style scoped>
.v-btn-toggle>.v-btn.v-btn--active {
    border-bottom: 5px solid #514773;
    opacity: 1;
}
</style>
